import React from 'react'
import styled from 'styled-components'
import SideNavBlogsNameData from '../Data/SideNavBlogsNameData.json'
import { LinkHubspot } from './LinkHubspot'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppSideNavBlogsNameWapper = styled.div`
  .wapperNav {
    border: 1px solid #34a1d5;
    border-radius: 18px;
  }
  ul {
    list-style: none;
    width: 100%;
    text-decoration: none;
    padding: unset;
  }
  li {
    width: auto;
    text-decoration: none;
    font-size: 19px;
    color: #047cc2;
    width: 500px;
    padding: 20px;
    @media (max-width: 420px) {
      width: 330px;
    }
  }
  li:hover {
    text-decoration: underline;
    color: #34a1d5;
  }
  .MainUnder {
    text-decoration: none;
  }
  .BorderHeading {
    background-color: #b1e0f6;
    border-top-right-radius: 18px;
    border-top-left-radius: 18px;
  }
  .NavBarHeading {
    text-align: center;
    font-size: 1.5rem;
    margin: auto;
    color: #047cc2;
    padding: 10px;
  }
`

export const AppSideNavBlogsName = () => {
  return (
    <AppSideNavBlogsNameWapper>
      <div className="wapperNav">
        <div className="BorderHeading">
          <ReUseHTwotag Heading="Related Blogs" HeadingStyle="NavBarHeading" />
        </div>
        {SideNavBlogsNameData.map((data, index) => {
          return (
            <ul key={index} className="NavSideUnder">
              <LinkHubspot to={data.ShiftUrl} className="MainUnder">
                <li className="NavSideList">{data.Title}</li>
              </LinkHubspot>
            </ul>
          )
        })}
      </div>
    </AppSideNavBlogsNameWapper>
  )
}
