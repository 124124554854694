import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOne:
      'Each of these examples have one thing in common – they drive investments in the technology required to gain access to company spend visibility, and therefore spend management systems like Raindrop.',
    ParaTwoLeft:
      'When the COVID-19 pandemic hit, a material number of retail companies hadn’t yet sufficiently invested in their digital stores. While retail businesses of all shapes and sizes are used to dealing with challenges, these unforeseen events and lifestyle changes have accelerated their challenges at a staggering pace. In 2019, 10,000 stores closed. In 2020, a record',
    ParaTwoAnchorOne: '12,200',
    ParaTwoBetweenOne: 'brick-and-mortar storefronts fell in the US alone; and in 2021,',
    ParaTwoAnchorTwo: '8,700 chain stores',
    ParaTwoBetweenTwo: 'disappeared from British high streets. In the wake of these significant losses,',
    ParaTwoAnchorThree: 'retailers',
    ParaTwoBetweenThree: 'who achieved success did so by embracing',
    ParaTwoAnchorFour: 'online technologies.',
    ParaTwoBetweenFour: 'They have also equipped their team with the',
    ParaTwoAnchorFive: 'right skills',
    ParaTwoRight: 'to meet rapidly evolving customer demands.',
    AnchorShiftOne:
      'https://fortune.com/2021/01/07/record-store-closings-bankruptcy-2020/#:~:text=A%20record%2012%2C200%20U.S.%20stores,commerce%2C%20pandemic%20changed%20retail%20forever',
    AnchorShiftTwo: 'https://www.bbc.co.uk/news/business-58433461',
    AnchorShiftThree:
      'https://www.chargedretail.co.uk/2020/05/15/retailers-doing-well-during-covid19-lockdown-nintendo-zalando-ocado-halfords/',
    AnchorShiftFour: 'https://www.growthengineering.co.uk/learning-technology-use-cases/',
    AnchorShiftFive:
      'https://www.mckinsey.com/~/media/mckinsey/industries/retail/our%20insights/rebooting%20retail%20how%20technology%20will%20shape%20the%20future%20of%20retail/rebooting-retail-how-technology-will-shape-the-future-of-retail.pdf',
    ImagePathOne: 'https://storage.googleapis.com/raindroppublic/website_data/RetailIndustry.jpg',
    ParaThreeLeft: 'Source:',
    ParaThreeAnchor: 'Gartner',
    ParaThreeRight: '',
    ShiftUrlParaThree: 'https://www.gartner.com/en/industries/retail',
    ParaFour: 'In this blog, we discuss other major concerns for US retailers.',
    HeadingOne: 'Retail Industry Challenges in 2022',
    HeadingTwo: '1.  Managing data silos, marketing, and sales together',
    ParaFive:
      'Leaders in the retail sector require technology solutions which not only handle the increasing amount of data but also convert it into useful information for effective decision-making and reduce their manpower requirements. In doing so, the data science approach leveraging AI and Cloud Technologies helps to create a truly unified omnichannel service.',
    HeadingThree: '2.  Changing approach to partnerships',
    ParaSixLeft: 'McKinsey leaders envision the',
    ParaSixAnchor: 'future of retail in 2030',
    ParaSixRight:
      'is tied to omnichannel shopping. According to their recent report, “A lot of the resource investment—both human capital and capital expenditure in dollars—needs to shift away from opening new brick-and-mortar stores and toward putting it into technology.” Advanced technologies, like AI, ML, and cloud computing, with strong data analytics, will transform and revolutionize all retail-store components industry-wide and on a global scale, inclusive of store operations, supply chains, procurement, contract management, and spend management.',
    ShiftUrlParaSix:
      'https://www.mckinsey.com/business-functions/marketing-and-sales/our-insights/omnichannel-shopping-in-2030',
    ImagePathTwo: 'https://storage.googleapis.com/raindroppublic/website_data/RetailIndustryTwo.jpg',
    HeadingFour: '3.  Strategic approach for automation',
    ParaSevenLeft:
      'Automation allows retailers to shorten reaction times by engaging with strategic partners to deliver omnichannel capabilities, with',
    ParaSevenAnchor: 'modularization.',
    ParaSevenRight:
      'It’s time for retailers to grab the opportunity and switch to agile systems through the use of technology upgrades and investments. In choosing a platform which serves across planning, sourcing, contract execution, and supplier management, retailers can benefit in every facet of their organization.',
    ShiftUrlParaSeven: 'https://www.raindrop.com/solutions',
    HeadingFive: '4.  Supply chain optimization',
    ParaEight:
      'The retail margins reduced drastically with consumers drifting to online shopping, rising shipping costs and taxes, and serious deficiencies on multiple fronts in their supply chains. Retailers require the aforementioned technical investments to solve supply chain challenges such as:',
    ParaNine: '◉ Managing peak season inventory',
    ParaTen: '◉  Improving inventory management accuracy',
    ParaEleven: '◉  Visibility to inventory in transit, stores, or warehouse',
    ParaTwelve: '◉  Planning demand and product availability',
    ParaThirteen: '◉  Integrating with a centralized system',
    ParaFourteen: '◉  Outdated legacy systems',
    ParaFifteenLeft: '◉  Preventing',
    ParaFifteenAnchor: 'The Bullwhip Effect',
    ParaFifteenRight: '',
    ShiftUrlParaFifteen: 'https://www.trindent.com/retail-supply-chain-challenges',
    ParaSixteen: '◉  Traditional supply chain practices',
    HeadingSix: '5.  Global economic factors and taxes',
    ParaSeventeen:
      'Consumer behaviour drives the retail market and it is one of the most unpredictable ones. Rising interest rates and inflation have a directly negative impact on consumer purchase power leading to increased product price increases, and potentially lower sales due to fewer available consumers for the respective good and/or service. The necessity for an effective spend management software platform, capable of hedging such factors can provide retailers a competitive and pricing advantage at this critical time. It is crucial to switch to advanced financial management in lean times to ensure work health and safety, maintain consumer confidence sharing and securing credible information, discounting, reducing rental overheads, and securely manage lease agreements.',
    HeadingSeven: 'In conclusion:',
    ParaEighteenLeft:
      'Now is the time to invest in advanced technologies. Raindrop offers digital transformation and advanced technological capabilities of AI, ML, automation, and data analytics allowing decision-makers to take measured action, powered by accurate numbers and figures. Retailers need to closely review and scrutinize critical points in the supply chain, procurement, and spend management systems. Raindrop’s effective procurement and spend management solution is the preferred choice of major retail brands, like',
    ParaEighteenAnchorOne: ' William Sonoma,',
    ParaEighteenAnchorTwo: 'Sephora,',
    ParaEighteenAnchorThree: 'FULLBEAUTY Brands,',
    ParaEighteenAnchorFour: ' The Container Store,',
    ParaEighteenAnchorFive: ' Workwear Outfitters,',
    ParaEighteenRight: 'in the US.',
    AnchorShiftSix: 'https://www.williams-sonoma.com/',
    AnchorShiftSeven: 'https://www.sephora.com/',
    AnchorShiftEight: 'https://www.fbbrands.com/',
    AnchorShiftNine: 'https://www.containerstore.com/welcome.htm',
    AnchorShiftTen: 'https://wwof.com/',
  },
]
