import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import Top5challengesintheretailindustryPendingData from '../Data/Top5challengesintheretailindustryPendingData'
import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'
import { ReUsePtagwithFiveAnchorTag } from './ReUsePtagwithFiveAnchorTag'

const AppTop5challengesintheretailindustryRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RetailIndustryContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .RetailIndustryHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RetailIndustryLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 8px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .RetailIndustryParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .ItalyPara {
    display: flex;
    color: #071741;
    max-width: 1150px;
    font-size: 1.2rem;
    line-height: 1.4;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
      font-size: 16px;
    }
  }
  .BlogsimageOne {
    width: 90%;
    display: flex;
    padding-left: 24px;
    /* float: right; */
    @media (max-width: 1090px) {
      float: unset;
      width: 100%;
    }
  }
  .RetailLeadParaStyleOne {
    padding: 0px 15px 0px 45px;
  }
  .RetailIndustryHeadingFiveEmail {
    padding: 15px 15px 15px 45px;
  }
  .ParaStyleMultiple {
    padding: 0px 15px 0px 45px;
  }
`

export const AppTop5challengesintheretailindustryRest = () => {
  return (
    <AppTop5challengesintheretailindustryRestWapper>
      <div>
        {Top5challengesintheretailindustryPendingData.map((data, index) => {
          return (
            <div className="MainDigitizationNeedsSection" key={index}>
              <div className="RetailIndustryContentSectionEmail">
                <ReUsePtag para={data.ParaOne} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUsePtagwithFiveAnchorTag
                  ParaLeft={data.ParaTwoLeft}
                  anchorParaOne={data.ParaTwoAnchorOne}
                  ParaBetweenOne={data.ParaTwoBetweenOne}
                  anchorParaTwo={data.ParaTwoAnchorTwo}
                  ParaBetweenTwo={data.ParaTwoBetweenTwo}
                  anchorParaThree={data.ParaTwoAnchorThree}
                  ParaBetweenThree={data.ParaTwoBetweenThree}
                  anchorParaFour={data.ParaTwoAnchorFour}
                  ParaBetweenFour={data.ParaTwoBetweenFour}
                  anchorParaFive={data.ParaTwoAnchorFive}
                  ParaRight={data.ParaTwoRight}
                  hrefOne={data.AnchorShiftOne}
                  hrefTwo={data.AnchorShiftTwo}
                  hrefThree={data.AnchorShiftThree}
                  hrefFour={data.AnchorShiftFour}
                  hrefFive={data.AnchorShiftFive}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailIndustryLeadParaStyle"
                />
                <img src={data.ImagePathOne} className="BlogsimageOne" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaThreeLeft}
                  anchorPara={data.ParaThreeAnchor}
                  ParaRight={data.ParaThreeRight}
                  href={data.ShiftUrlParaThree}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUsePtag para={data.ParaFour} paraStyle="RetailIndustryLeadParaStyleOne" />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUsePtag para={data.ParaFive} paraStyle="RetailIndustryLeadParaStyle" />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle="RetailIndustryHeadingEmail" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaSixLeft}
                  anchorPara={data.ParaSixAnchor}
                  ParaRight={data.ParaSixRight}
                  href={data.ShiftUrlParaSix}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <img src={data.ImagePathTwo} className="BlogsimageOne" />
                <ReUseHTwotag Heading={data.HeadingFour} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaSevenLeft}
                  anchorPara={data.ParaSevenAnchor}
                  ParaRight={data.ParaSevenRight}
                  href={data.ShiftUrlParaSeven}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailLeadParaStyleOne"
                />
                <ReUseHTwotag Heading={data.HeadingFive} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaEight} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaNine} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTen} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaEleven} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaTwelve} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaThirteen} paraStyle="ParaStyleMultiple" />
                <ReUsePtag para={data.ParaFourteen} paraStyle="ParaStyleMultiple" />
                <ReUsePtagwithAnchorTag
                  ParaLeft={data.ParaFifteenLeft}
                  anchorPara={data.ParaFifteenAnchor}
                  ParaRight={data.ParaFifteenRight}
                  href={data.ShiftUrlParaFifteen}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="ParaStyleMultiple"
                />
                <ReUsePtag para={data.ParaSixteen} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSix} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtag para={data.ParaSeventeen} paraStyle="ParaStyleMultiple" />
                <ReUseHTwotag Heading={data.HeadingSeven} HeadingStyle="RetailIndustryHeadingFiveEmail" />
                <ReUsePtagwithFiveAnchorTag
                  ParaLeft={data.ParaEighteenLeft}
                  anchorParaOne={data.ParaEighteenAnchorOne}
                  anchorParaTwo={data.ParaEighteenAnchorTwo}
                  anchorParaThree={data.ParaEighteenAnchorThree}
                  anchorParaFour={data.ParaEighteenAnchorFour}
                  anchorParaFive={data.ParaEighteenAnchorFive}
                  ParaRight={data.ParaEighteenRight}
                  hrefOne={data.AnchorShiftSix}
                  hrefTwo={data.AnchorShiftSeven}
                  hrefThree={data.AnchorShiftEight}
                  hrefFour={data.AnchorShiftNine}
                  hrefFive={data.AnchorShiftTen}
                  target="_blank"
                  rel="noopener noreferrer"
                  anchorStyle="achorPara"
                  paraStyle="RetailIndustryLeadParaStyle"
                />
              </div>
            </div>
          )
        })}
      </div>
    </AppTop5challengesintheretailindustryRestWapper>
  )
}
